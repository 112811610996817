/*
Theme Name: SPARKER
Theme URI: http://www.ansonika.com/sparker/
Author: Ansonika
Author URI: http://themeforest.net/user/Ansonika/

[Table of contents] 

1. SITE STRUCTURE
- 1.1 Typography
- 1.2 Buttons
- 1.3 Structure

2. CONTENT
- 2.1 Courses

3. COMMON
- 3.1 Forms
- 3.2 Pagination
- 3.3 Misc
- 3.4 Spacing
- 3.5 Select

/*============================================================================================*/
/* 1.  SITE STRUCTURE */
/*============================================================================================*/
/*Import*/
/* @tailwind base;
@tailwind components; 
@tailwind utilities; */
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,400i,500,500i,600,600i,700,700i,800");

.h100 {
  height: 70vh !important;
}
.h-90{
  height: 88%;
}
label{
  font-weight: 500;
  margin-bottom: 4px;
}
.btn-normal {
  text-transform: capitalize;
  display: inline-block;
  font-size: 14px;
  border-radius: 4px;
  /* margin-right: 5px; */
  line-height: normal;
  padding: 8px 20px;
  color:#fff;

}
.btn-green {
  background-color: #23a76b;
  border:1px solid #23a76b;
}
html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

/* body.sticky-footer {
  margin-bottom: 62px;
} */
body.sticky-footer .content-wrapper {
  min-height: calc(100vh - 62px - 62px);
}

body.fixed-nav {
  padding-top: 56px;
}

.content-wrapper {
  min-height: calc(100vh - 62px);
  padding-top: 1rem;
}

.scroll-to-top {
  position: fixed;
  right: 15px;
  bottom: 3px;
  display: none;
  width: 50px;
  height: 50px;
  text-align: center;
  color: white;
  background: rgba(52, 58, 64, 0.5);
  line-height: 45px;
}
.scroll-to-top:focus, .scroll-to-top:hover {
  color: white;
}
.scroll-to-top:hover {
  background: #343a40;
}
.scroll-to-top i {
  font-weight: 800;
}

.smaller {
  font-size: 0.7rem;
}

.o-hidden {
  overflow: hidden !important;
}

.z-0 {
  z-index: 0;
}

.z-1 {
  z-index: 1;
}

/*-------- Navigation --------*/
#mainNav .navbar-collapse {
  overflow: auto;
  max-height: 75vh;
}
#mainNav .navbar-collapse .navbar-nav .nav-item .nav-link {
  cursor: pointer;
}
#mainNav .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
  float: right;
  content: '\f107';
  font-family: 'FontAwesome';
}
#mainNav .navbar-collapse .navbar-sidenav .nav-link-collapse.collapsed:after {
  content: '\f105';
}
#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level {
  padding-left: 0;
}
#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level > li > a,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level > li > a {
  display: block;
  padding: 0.5em 0;
}
#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level > li > a:focus, #mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level > li > a:hover,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level > li > a:focus,
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level > li > a:hover {
  text-decoration: none;
}
#mainNav .navbar-collapse .navbar-sidenav .sidenav-second-level > li > a {
  padding-left: 1em;
}
#mainNav .navbar-collapse .navbar-sidenav .sidenav-third-level > li > a {
  padding-left: 2em;
}
#mainNav .navbar-collapse .sidenav-toggler {
  display: none;
}
#mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link {
  position: relative;
  min-width: 45px;
}
#mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link:after {
  float: right;
  width: auto;
  content: '\f105';
  border: none;
  font-family: 'FontAwesome';
}
#mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link .indicator {
  position: absolute;
  top: 5px;
  left: 21px;
  font-size: 10px;
}
#mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown.show > .nav-link:after {
  content: '\f107';
}
#mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown-item > .dropdown-message {
  overflow: hidden;
  max-width: none;
  text-overflow: ellipsis;
}
.site-text{
  color: #23a76b;
}
.cityimage label {
  margin-bottom: 30px;
  font-size: 12px;
  height: auto;
  padding: 5px !important;
  position: relative;
  top: 20px;
}
@media (min-width: 992px) {
  #mainNav .navbar-brand {
    width: 250px;
  }
  #mainNav .navbar-collapse {
    overflow: visible;
    max-height: none;
  }
  #mainNav .navbar-collapse .navbar-sidenav {
    position: absolute;
    top: 0;
    left: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: 88px;
  }
  .site-text{
    color: #23a76b;
  }
  .cityimage label {
    margin-bottom: 30px;
    font-size: 12px;
    height: auto;
    padding: 10px !important;
}
.cityimage img{
  width: 100%;
}
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item {
    width: 250px;
    padding: 0;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item > .nav-link {
    padding: 1em;
    padding-left: 18px;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level,
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level {
    padding-left: 0;
    list-style: none;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li,
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li {
    width: 250px;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a,
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a {
    padding: 1em;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a {
    padding-left: 2.75em;
  }
  #mainNav .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a {
    padding-left: 3.75em;
  }
  #mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link {
    min-width: 0;
  }
  #mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link:after {
    width: 24px;
    text-align: center;
  }
  #mainNav .navbar-collapse .navbar-nav > .nav-item.dropdown .dropdown-menu > .dropdown-item > .dropdown-message {
    max-width: 300px;
  }
}

#mainNav.fixed-top .sidenav-toggler {
  display: none;
}
@media (min-width: 992px) {
  #mainNav.fixed-top .navbar-sidenav {
    /* height: calc(100vh - 111px); */
    height:100vh;
  }
  #mainNav.fixed-top .sidenav-toggler {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    margin-top: calc(100vh - 49px);
  }
  #mainNav.fixed-top .sidenav-toggler > .nav-item {
    width: 250px;
    padding: 0;
  }
  #mainNav.fixed-top .sidenav-toggler > .nav-item > .nav-link {
    padding: 1em;
  }
}
#mainNav.fixed-top.navbar-dark .sidenav-toggler {
  background-color: #212529;
  border-top: 1px solid #343a40;
}
#mainNav.fixed-top.navbar-dark .sidenav-toggler a i {
  color: #adb5bd;
}
#mainNav.fixed-top.navbar-light .sidenav-toggler {
  background-color: #dee2e6;
}
#mainNav.fixed-top.navbar-light .sidenav-toggler a i {
  color: rgba(0, 0, 0, 0.5);
}

body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler {
  overflow-x: hidden;
  width: 55px;
}
body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler .nav-item,
body.sidenav-toggled #mainNav.fixed-top .sidenav-toggler .nav-link {
  width: 55px !important;
}
body.sidenav-toggled #mainNav.fixed-top #sidenavToggler i {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
}

#mainNav.static-top .sidenav-toggler {
  display: none;
}
@media (min-width: 992px) {
  #mainNav.static-top .sidenav-toggler {
    display: flex;
  }
}

body.sidenav-toggled #mainNav.static-top #sidenavToggler i {
  -webkit-transform: scaleX(-1);
  -moz-transform: scaleX(-1);
  -o-transform: scaleX(-1);
  transform: scaleX(-1);
  filter: FlipH;
  -ms-filter: 'FlipH';
}

.content-wrapper {
  overflow-x: hidden;
  background: white;
}
.my_row {
  position: relative;
}
.my_row::after {
  content: '';
  position: absolute;
  bottom: 15px;
  width: 98%;
  margin-left: auto;
  margin-right: auto;
  background-color: #0001;
  left: 0;
  right: 0;
}
.my_row:not(:last-child):after{
  height: 1px;
}
@media (min-width: 992px) {
  .content-wrapper {
    margin-left: 250px;
  }
  .my_row .col-lg-2 {
    width: auto;
  }
  .my_row .col-lg-2:not(:last-child) {
    flex: 1;
  }
}

#sidenavToggler i {
  font-weight: 800;
}

.navbar-sidenav-tooltip.show {
  display: none;
}

@media (min-width: 992px) {
  body.sidenav-toggled .content-wrapper {
    margin-left: 55px;
  }
}
body.sidenav-toggled .navbar-sidenav {
  overflow-x: hidden;
  width: 55px;
}
body.sidenav-toggled .navbar-sidenav .nav-link-text {
  display: none;
}
body.sidenav-toggled .navbar-sidenav .nav-item,
body.sidenav-toggled .navbar-sidenav .nav-link {
  width: 55px !important;
}
body.sidenav-toggled .navbar-sidenav .nav-item:after,
body.sidenav-toggled .navbar-sidenav .nav-link:after {
  display: none;
}
body.sidenav-toggled .navbar-sidenav-tooltip.show {
  display: flex;
}

#mainNav.navbar-dark .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
  color: #868e96;
}
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item > .nav-link {
  color: #202020;
}
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item > .nav-link:hover {
  color: #212121;
}
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a {
  color: #ced4da;
}
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a:focus, #mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a:hover,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a:focus,
#mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a:hover {
  color: #f8f9fa;
}
#mainNav.navbar-dark .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link:after {
  color: #adb5bd;
}
@media (min-width: 992px) {
  #mainNav.navbar-dark .navbar-collapse .navbar-sidenav {
    background: #fafafa;
    padding-top: 10px;
    border-top: 1px solid #e9e9e9;
  }
  #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a {
    color: white !important;
    background-color: #343a40;
  }
  #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a:focus, #mainNav.navbar-dark .navbar-collapse .navbar-sidenav li.active a:hover {
    color: white;
  }
  #mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level,
  #mainNav.navbar-dark .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level {
    background: #343a40;
  }
}

#mainNav.navbar-light .navbar-collapse .navbar-sidenav .nav-link-collapse:after {
  color: rgba(0, 0, 0, 0.5);
}
#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item > .nav-link {
  color: rgba(0, 0, 0, 0.5);
}
#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item > .nav-link:hover {
  color: rgba(0, 0, 0, 0.7);
}
#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a {
  color: rgba(0, 0, 0, 0.5);
}
#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a:focus, #mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level > li > a:hover,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a:focus,
#mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level > li > a:hover {
  color: rgba(0, 0, 0, 0.7);
}
#mainNav.navbar-light .navbar-collapse .navbar-nav > .nav-item.dropdown > .nav-link:after {
  color: rgba(0, 0, 0, 0.5);
}
@media (min-width: 992px) {
  #mainNav.navbar-light .navbar-collapse .navbar-sidenav {
    background: #f8f9fa;
  }
  #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a {
    color: #000 !important;
    background-color: #e9ecef;
  }
  #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a:focus, #mainNav.navbar-light .navbar-collapse .navbar-sidenav li.active a:hover {
    color: #000;
  }
  #mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-second-level,
  #mainNav.navbar-light .navbar-collapse .navbar-sidenav > .nav-item .sidenav-third-level {
    background: #f8f9fa;
  }
}

/*-------- Cards and footer --------*/
.card.dashboard {
  border: none;
}
.card.dashboard h5 {
  color: #fff !important;
}

.card-body-icon {
  position: absolute;
  z-index: 0;
  top: 10px;
  right: 10px;
  font-size: 32px;
  font-size: 2rem;
  opacity: 0.3;
}

@media (min-width: 576px) {
  .card-columns {
    column-count: 1;
  }
}
@media (min-width: 768px) {
  .card-columns {
    column-count: 2;
  }
}
@media (min-width: 1200px) {
  .card-columns {
    column-count: 2;
  }
}

footer.sticky-footer {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 56px;
  border-top: 1px solid #ededed;
  line-height: 55px;
}
@media (min-width: 992px) {
  footer.sticky-footer {
    width: calc(100% - 250px);
  }
}

@media (min-width: 992px) {
  body.sidenav-toggled footer.sticky-footer {
    width: calc(100% - 55px);
  }
}

/*-------- 1.1 Typography --------*/
body {
  background: #f8f8f8;
  color: #777 !important;
  font-family: "Poppins", Helvetica, sans-serif;
  font-size: 14px;
  font-size: 0.875rem;
}

.content-wrapper {
  background: #f8f8f8;
  border-left: 1px solid #e9e9e9;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #333 !important;
}

p {
  margin-bottom: 0px;
}

hr {
  margin: 15px 0 30px 0;
  border-color: #ababab;
}

hr.double {
  margin: 15px 0 30px 0;
  border-color: #ededed;
  border-width: 2px;
}

strong {
  font-weight: 500;
}

nav .input-group-btn {
  position: relative;
  -ms-flex-align: stretch;
  align-items: stretch;
  font-size: 0;
  white-space: nowrap;
}
nav .input-group-btn button.btn {
  -webkit-border-radius: 0 3px 3px 0 !important;
  -moz-border-radius: 0 3px 3px 0 !important;
  -ms-border-radius: 0 3px 3px 0 !important;
  border-radius: 0 3px 3px 0 !important;
}

/*-------- 1.2 Buttons --------*/
/*General links color*/
a {
  color: #0054a6;
  text-decoration: none;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  outline: none;
}
a:hover, a:focus {
  color: #333;
  text-decoration: none;
  outline: none;
}

a.btn_1,
.btn_1 {
  border: none;
  color: #fff;
  background: #004dda;
  cursor: pointer;
  padding: 10px 15px;
  display: inline-block;
  outline: none;
  font-size: 13px;
  font-size: 0.8125rem;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  border-radius: 25px;
  line-height: 1;
  font-weight: 500;
}
a.btn_1.gray,
.btn_1.gray {
  background: #e9ecef;
  color: #868e96;
}
a.btn_1.gray.approve:hover,
.btn_1.gray.approve:hover {
  background: #23a76b;
  color: #fff;
}
a.btn_1.gray.delete:hover,
.btn_1.gray.delete:hover {
  background: #dc3545;
  color: #fff;
}
a.btn_1.medium,
.btn_1.medium {
  padding: 12px 45px;
  font-size: 16px;
  font-size: 1rem;
}
a.btn_1:hover,
.btn_1:hover {
  background: #004dda;
  color: #fff;
}

.btn-primary {
  background-color: #004dda;
  border-color: #004dda;
}
.btn-primary:hover {
  background-color: #0054a6;
  border-color: #0054a6;
}

/*-------- 1.3 Structure --------*/
/* Header */
.bg-default {
  background-color: #fafafa;
  border-bottom: 1px solid #e9e9e9;
}

/*============================================================================================*/
/* 2.  CONTENT */
/*============================================================================================*/
/*-------- 2.1 Courses --------*/
ul.buttons {
  list-style: none;
  margin: 0 0 30px 0;
  padding: 0;
  position: absolute;
  top: 30px;
  right: 30px;
}
@media (max-width: 767px) {
  ul.buttons {
    /* For less than 767px */
    position: static;
  }
}
ul.buttons li {
  display: inline-block;
  margin-right: 3px;
}

ul.booking_list {
  list-style: none;
  margin: 15px 0 25px 0;
  padding: 0;
}
ul.booking_list li {
  margin-bottom: 5px;
}
ul.booking_list li strong {
  width: 150px;
  display: inline-block;
}
@media (max-width: 767px) {
  ul.booking_list li strong {
    /* For less than 767px */
    display: block;
    width: auto;
  }
}

/*============================================================================================*/
/* 3.  COMMON */
/*============================================================================================*/
/*-------- 3.1 Forms --------*/
.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: none;
  box-shadow: none;
}

.form-group label {
  font-weight: 500;
  color: #333;
}

.form-control {
  font-size: 14px;
  font-size: 0.875rem;
  padding: .65rem;
}
.form-control.search-top {
  width: 300px;
  padding: .45rem;
  height: 38px;
  border: none;
}

select.form-control:not([size]):not([multiple]) {
  height: calc(2.68rem + 2px);
}

label.fix_spacing {
  padding-top: 12px !important;
  display: inline-block;
  text-transform: uppercase;
}

/*-------- 3.2 Pagination --------*/
.page-item.active .page-link {
  background-color: #004dda;
  border-color: #004dda;
}

.page-item.disabled .page-link {
  color: #999;
  border-color: #e1e8ed;
}

.page-link {
  color: #0054a6;
  border: 1px solid #e1e8ed;
}
.page-link:focus, .page-link:hover {
  color: #fff;
  text-decoration: none;
  background-color: #004dda;
  border-color: #004dda;
}

/*-------- 3.3 Misc --------*/
.box_general {
  background-color: #fff;
  padding: 15px 30px 0 30px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 30px;
  color: #777;
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}
.box_general hr {
  margin-left: -30px;
  margin-right: -30px;
}
.box_general.padding_bottom {
  padding-bottom: 20px;
}

.header_box {
  padding-bottom: 10px;
}
.header_box.version_2 {
  border-bottom: 1px solid #ededed;
  margin: 0 -30px;
  padding: 0 30px 10px 30px;
  margin-bottom: 15px;
}
.header_box.version_2 i {
  margin-right: 10px;
  color: #ddd;
}
.header_box h2 {
  font-size: 21px;
  font-size: 1.3125rem;
  display: inline-block;
  margin-top: 5px;
}

.list_general > ul {
  padding: 0;
  margin: 0;
  list-style: none;
}
.list_general > ul > li {
  margin: 0 -30px 0 -30px;
  position: relative;
  padding: 30px 30px 5px 120px;
  border-top: 1px solid #ededed;
}
@media (max-width: 767px) {
  .list_general > ul > li {
    /* For less than 767px */
    padding-left: 30px;
  }
}
.list_general > ul > li:hover {
  background-color: #f8f9fa;
}
.list_general > ul > li figure {
  width: 70px;
  height: 70px;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  left: 30px;
  top: 30px;
}
@media (max-width: 767px) {
  .list_general > ul > li figure {
    /* For less than 767px */
    position: relative;
    top: 0;
    left: 0;
  }
}
.list_general > ul > li figure img {
  width: 70px;
  height: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.list_general > ul h4 {
  font-size: 21px;
  font-size: 1.3125rem;
}
.list_general > ul span {
  float: right;
  font-style: italic;
  color: #adb5bd;
}
@media (max-width: 767px) {
  .list_general > ul span {
    /* For less than 767px */
    float: right;
    display: inline-block;
  }
}

.rating {
  margin-right: 10px;
  color: #ced4da !important;
}
.rating .yellow {
  color: #ffc107;
}

.unread, .read, .pending, .cancel, .approved {
  background-color: #dc3545;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  border-radius: 50px;
  font-size: 12px;
  font-size: 0.75rem;
  color: #fff;
  font-style: normal;
  padding: 3px 12px 2px 12px;
  margin-left: 3px;
  position: relative;
  top: -3px;
  line-height: 1;
}

.unread, .cancel {
  background-color: #dc3545;
}

.read, .approved {
  background-color: #28a745;
}

.pending {
  background-color: #fd7e14;
}

/* text-based popup styling */
.white-popup {
  position: relative;
  background: #FFF;
  padding: 80px 25px 25px 25px;
  width: auto;
  max-width: 500px;
  margin: 0 auto;
}

.small-dialog-header {
  font-size: 22px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  display: inline-block;
  background-color: #f6f6f6;
  padding: 15px 25px;
}

.small-dialog-header h3 {
  padding: 0;
  margin: 0;
  font-size: 21px;
}

/*-------- 3.4 Spacing --------*/
.add_bottom_15 {
  margin-bottom: 15px;
}

.add_bottom_30 {
  margin-bottom: 30px;
}

.add_bottom_45 {
  margin-bottom: 45px;
}
@media (max-width: 767px) {
  .add_bottom_45 {
    /* For less than 767px */
    margin-bottom: 30px;
  }
}

.add_bottom_60 {
  margin-bottom: 60px;
}

.add_bottom_75 {
  margin-bottom: 75px;
}

.add_top_20 {
  margin-top: 20px;
}

.add_top_30 {
  margin-top: 30px;
}

.add_top_60 {
  margin-top: 60px;
}

.more_padding_left {
  padding-left: 40px;
}

.nomargin_top {
  margin-top: 0;
}

.nopadding {
  margin: 0 !important;
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.margin_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media (max-width: 767px) {
  .margin_60 {
    /* For less than 767px */
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.margin_60_35 {
  padding-top: 60px;
  padding-bottom: 35px;
}
@media (max-width: 767px) {
  .margin_60_35 {
    /* For less than 767px */
    padding-top: 30px;
    padding-bottom: 5px;
  }
}

.margin_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media (max-width: 991px) {
  .margin_120 {
    /* For less than 991px */
    padding-top: 60px;
    padding-bottom: 35px;
  }
  
}

.margin_120_95 {
  padding-top: 120px;
  padding-bottom: 95px;
}
@media (max-width: 991px) {
  .margin_120_95 {
    /* For less than 991px */
    padding-top: 60px;
    padding-bottom: 35px;
  }
  
}
@media (max-width: 767px) {
  .margin_120_95 {
    /* For less than 767px */
    padding-top: 30px;
    padding-bottom: 5px;
  }
}

/*-------- 3.5 Select --------*/
.styled-select select {
  background: transparent;
  width: 107%;
  padding: 5px;
  padding-left: 15px;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  height: 41px;
  color: #868e95;
  margin: 0;
  font-weight: 400;
  -moz-appearance: window;
  -webkit-appearance: none;
  appearance:none;
  cursor: pointer;
  outline: none;
}

.styled-select select:focus {
  color: #555;
}

.styled-select {
  width: 100%;
  overflow: hidden;
  height: 44px;
  /* background: #fff url(../img/arrow_down.svg) no-repeat right center; */
  border: 1px solid #ced4da;
  -webkit-border-radius: 0.25rem;
  -moz-border-radius: 0.25rem;
  -ms-border-radius: 0.25rem;
  border-radius: 0.25rem;
}
.flex_auto{
    flex: 0 0 auto !important;
}
.styled-select select::-ms-expand {
  display: none;
}

.styled-select select:focus {
  outline: none;
  box-shadow: none;
}

.filter {
  float: right;
  width: 150px;
  /* display: inline-block; */
}

.sbHolder {
  background-color: #fff;
  border: 1px solid #e1e8ed;
  height: 32px;
  position: relative;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  border-radius: 3px;
  min-width: 160px;
}

.sbSelector {
  display: block;
  height: 32px;
  left: 0;
  line-height: 32px;
  outline: none;
  overflow: hidden;
  position: absolute;
  text-indent: 10px;
  top: 0;
  width: 100%;
}

.sbSelector:link,
.sbSelector:visited,
.sbSelector:hover {
  color: #555;
  outline: none;
  text-decoration: none;
  font-weight: 600;
  font-size: 12px;
  font-size: 0.75rem;
}

.sbToggle {
  position: absolute;
  display: block;
  height: 32px;
  outline: none;
  right: 0;
  top: 0;
  width: 35px;
  text-align: center;
  line-height: 32px;
}
.sbToggle:after {
  font-family: 'FontAwesome';
  font-size: 14px;
  content: "\f0d7";
  text-align: center;
  color: #555;
}

.sbHolderDisabled {
  background-color: #ededed;
  border: solid 1px #ededed;
}

.sbOptions {
  background-color: #fff;
  border: solid 1px #ededed;
  list-style: none;
  left: -1px;
  right: -1px;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 40px;
  z-index: 10;
  font-size: 12px;
  font-size: 0.75rem;
}

.sbOptions li {
  padding: 0 7px;
  float: none !important;
  border-bottom: solid 1px #ededed;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  -ms-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
  width: 100%;
}

.sbOptions li:last-child {
  border-bottom: none;
  float: none !important;
  margin: 0;
  padding-left: 10px;
}

.sbOptions li:hover {
  color: #0054a6;
}

.sbOptions a {
  display: block;
  outline: none;
  padding: 7px 0 7px 3px;
  text-transform: capitalize;
}

.sbOptions a:link,
.sbOptions a:visited {
  color: #555;
  text-decoration: none;
}

.sbOptions a:hover,
.sbOptions a:focus,
.sbOptions a.sbFocus {
  color: #0054a6;
}

.sbOptions li.last a {
  border-bottom: none;
}

.sbOptions .sbDisabled {
  border-bottom: dotted 1px #fff;
  color: #999;
  display: block;
  padding: 7px 0 7px 3px;
}

.sbOptions .sbGroup {
  border-bottom: dotted 1px #fff;
  color: #555;
  display: block;
  font-weight: bold;
  padding: 7px 0 7px 3px;
}

.sbOptions .sbSub {
  padding-left: 17px;
}
/* @tailwind base;
@tailwind components; */
@tailwind utilities;

.selection_felid select,
.text_felid input {
  width: 100%;
  border-radius: 5px;
  padding: 5px 15px;
  background-color: #fff;
  height: 40px;
  border: 1px solid #ccc;
}
.text_felid{
  margin-bottom: 15px;
}
.text_felid input:disabled,
.text_felid2.disabled{
  background-color: #0001;
}
.text_felid2 input:disabled  {
  background-color: transparent;
}
.text_felid2 {
  border: 1px dashed #ccc;
  border-radius: 5px;
}
.text_felid2 input{
  border:0;
  border-radius:5px 0 0 5px !important;
  padding-left: 6px;
}

.text_felid textarea:focus,
.text_felid input:focus {
  border-color: #29358d;
}
.radio-group label {
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-weight: 600;
  color: #333;
}
.radio-group label {
  display: block;
  cursor: pointer;
}
.radio-group span:before 
{
  content: "";
  display: inline-block;
  vertical-align: -0.25em;
  height: 1rem;
  width: 1rem;
  
  border: 1px solid #ccc;
  margin-right: 6px;
  background-color: #fff;
}
.radio-group:not(.checkbox-group) span:before  {
  border-radius: 50%;
}

.radio-group:not(.checkbox-group) input:checked + span:before {
  background-image: radial-gradient(
    circle closest-side,
    #28a745 0%,
    #28a745 55%,
    transparent 50%,
    transparent 100%
  );
}
.checkbox-group input:checked + span:before{
  content: "\f00c";
    font-family: 'FontAwesome';
    vertical-align: middle;
    color: #fff;
    background-color: #28a745;
    border-color: #28a745;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
}
.radio-group input:disabled + span {
  opacity: 0.5;
}

.radio-group input:disabled:checked + span:before {
  background-image: radial-gradient(
    circle closest-side,
    rgba(0, 0, 0, 0.5) 0%,
    rgba(0, 0, 0, 0.5) 50%,
    transparent 50%,
    transparent 100%
  );
}

.radio-group input[type="radio"],
.radio-group input[type="checkbox"] 
{
  display: none;
}

.radio-group:before,
.radio-group:after {
  content: "";
  display: table;
}

.radio-group:after {
  clear: both;
}

.radio-group label {
  float: left;
  cursor: pointer;
}
.radio-group span{
  font-weight: 500;
}
.radio-group > div:not(:last-child) span{
  margin-right: 15px;
}
/*=================*/
.selection_felid.error label,
.text_felid.error label,
.error.mb-0 label {
  color: #dc3545;
  margin-bottom: 4px;
}

.text_felid.error textarea,
.text_felid.error input:not(.text_felid2.error input),
.text_felid2.error
 {
  border: 1px solid #dc3545;
}
.text_felid2.error {
  border-style: dashed;
}
.selection_felid.error > div {
  border: 1px solid #dc3545;
  border-radius: 5px;
  padding-bottom: 0;
}
.selection_felid label i, .text_felid label i {
  margin-right: 5px;
}
.tag_list {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  /* background-color: #f6f6f6; */
  padding: 0px;
  margin-top: 5px;
  flex-wrap: wrap;
}
.tag {
  background-color: #999;
  color: #fff;
  padding: 2px 5px 2px 5px;
  border-radius: 4px;
  margin: 2px;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.jodit-workplace  {
  /* height: 220px !important; */
  overflow: hidden;
  overflow-y: auto;
  min-height:220px!important;
}
.tag span {
  font-weight: 400;
  display: inline-block;
  font-size: 13px;
}
.tag small {
  background-color: transparent;
  color: #fff;
  border-radius: 30px;
  line-height: normal;
  width: 100%;
  font-weight: 400;
  height: 100%;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  font-size: 12px;
  flex: 0 0 0;
}
.react-tel-input {
  float: left;
  width: 100%;
}

.react-tel-input input{
  height: calc(2.34rem + 2px);
  border-color: #ccc;
  width: 100%;
}
.react-tel-input .form-control{
  width: 100%;
  height: 39px;
}
.selection_felid > div > button{
  padding: 3.6px 10px 3.6px 3px;
  font-size: 14px !important;
  color: #777;
} 
.selection_felid > div{
  background-color: #fff;
}
.Image-error label{
border-color: #f0151f;
}
.selection_felid {
  margin-bottom: 30px;
}
.Image-error span:not(.fa-cloud-upload){
  background-color: #f0151f;
}
.stsearch-box input{
  border:none;
  height: 30px;
  border-radius: 4px;
}
.stsearch-box input:focus{
  outline: none;
}
.stdropdown-input.stsearch-box{
  /* padding: 0px; */
  background-color: #fff;
  border-radius: 4px;
}
.error.text-felid label{
  color: #f0151f;
}
.text-felid label{
  float: left;
  width: 100%;
  /* margin-bottom: 8px; */
}
.error.text-felid .react-tel-input input {
  border-color: #f0151f;
}

/* New Dashbaord css */
nav.header {
  border-bottom-width: 1px;
  border-color: #e9eef5;
  transition-duration: .15s;
  transition-duration: .25s;
  transition-property: all;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
}
.min-h-100vh {
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh)*100)
}
*,:after,:before {
  border: 0 solid #e5e7eb;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
}
.main-content {
  display: grid;
  grid-template-columns: repeat(1,minmax(0,1fr));
  margin-top: 20px;
  place-content: start;
  transition-duration: .15s;
  transition-duration: .25s;
  transition-property: width,margin-left,margin-right,padding-left,padding-right;
  transition-timing-function: cubic-bezier(.4,0,.2,1);
  transition-timing-function: cubic-bezier(.4,0,1,1)
}
.border-l-info {
  border-left-color: #0ea5e9
}

.border-l-secondary {
  border-left-color: #f000b9
}

.border-l-warning {
  border-left-color: #ff9800
}
.avatar:not(.h-16,.w-16) {
  display: inline-flex;
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.avatar>img {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: center;
  object-position: center
}

.avatar>.is-initial,.avatar>img {
  height: 100%;
  width: 100%
}

.avatar>.is-initial {
  align-items: center;
  display: flex;
  font-weight: 500;
  justify-content: center
}

.mask {
  -webkit-mask-position: center;
  mask-position: center;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
  -webkit-mask-size: contain;
  mask-size: contain
}

.mask.is-squircle {
  -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E");
  mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='200' height='200' fill='none'%3E%3Cpath fill='%23000' d='M100 0C20 0 0 20 0 100s20 100 100 100 100-20 100-100S180 0 100 0z'/%3E%3C/svg%3E")
}

.sidebar_admin li:not(:last-child){
  border-bottom: 1px solid #0001;
}

.timeline {
    --line: 1px;
    --size: 12px;
    display: flex;
    flex-direction: column
}

.timeline.line-space {
    --space: 12px;
}

.timeline .timeline-item {
    display: flex;
    flex: 1 1 0%;
    padding-bottom: 2rem;
    position: relative
}

.timeline .timeline-item .timeline-item-point {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 24px;
    justify-content: center;
    margin: 0;
    position: relative;
    width: 24px
}

.timeline .timeline-item:before {
    --tw-translate-x: calc(24px/2 - var(--line)/2);
    --tw-content: "";
    content: var(--tw-content);
    inset: 0;
    order: -1;
    position: absolute;
    transform: translate(var(--tw-translate-x),var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    width: var(--line)
}

.timeline .timeline-item:not(:last-child):before {
    background-color: #cbd5e1
}

:is(.dark .timeline .timeline-item:not(:last-child)):before {
    background-color: #5c6b8a
}

.timeline .timeline-item:last-child:before {
    --tw-gradient-from: #cbd5e1 var(--tw-gradient-from-position);
    --tw-gradient-to: rgba(203,213,225,0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to);
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
    background-image: linear-gradient(to bottom,var(--tw-gradient-stops))
}

:is(.dark .timeline .timeline-item:last-child):before {
    --tw-gradient-from: #5c6b8a var(--tw-gradient-from-position);
    --tw-gradient-to: rgba(92,107,138,0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from),var(--tw-gradient-to)
}

.timeline .timeline-item:last-child {
    padding-bottom: 0
}

.timeline.line-space .timeline-item:before {
    bottom: var(--space);
    top: calc(24px + var(--space))
}
.is-scrollbar-hidden {
  scrollbar-width: none
}

.is-scrollbar-hidden::-webkit-scrollbar {
  display: none
}

@supports selector(::-webkit-scrollbar) {
  .scrollbar-sm::-webkit-scrollbar {
      height: .375rem;
      width: .375rem
  }

  .scrollbar-sm:focus-within::-webkit-scrollbar-thumb,.scrollbar-sm:hover::-webkit-scrollbar-thumb {
      background-color: rgba(203,213,225,.8);
      border-radius: 9999px
  }

  :is(.dark .scrollbar-sm:focus-within)::-webkit-scrollbar-thumb,:is(.dark .scrollbar-sm:hover)::-webkit-scrollbar-thumb {
      background-color: #5c6b8a
  }

  .scrollbar-sm::-webkit-scrollbar-thumb:hover {
      background-color: #94a3b8
  }

  :is(.dark .scrollbar-sm)::-webkit-scrollbar-thumb:hover {
      background-color: #697a9b
  }
}
.progress {
  border-radius: 9999px;
  display: flex;
  overflow: hidden;
  position: relative;
  width: 100%
}

.progress .is-indeterminate {
  animation: progress-increase 2s cubic-bezier(.4,0,.2,1) infinite
}

.progress .is-active:before {
  --tw-content: "";
  animation: progress-active 2s cubic-bezier(.55,.2,.3,.67) infinite;
  background-color: #fff;
  border-radius: .25rem;
  content: var(--tw-content);
  inset: 0;
  position: absolute
}

@keyframes progress-increase {
  0% {
      left: -5%;
      width: 5%
  }

  to {
      left: 125%;
      width: 100%
  }
}

@keyframes progress-active {
  0% {
      opacity: .4;
      transform: translateX(-100%)
  }

  to {
      opacity: 0;
      transform: translateX(0)
  }
}
/* ========================================================================================== 
                                        >> AG Grid
 ========================================================================================== */

 .ag-theme-alpine .ag-root-wrapper,
 .ag-theme-alpine .ag-header,
 .ag-theme-alpine .ag-floating-bottom,
 .ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell,
 .ag-theme-alpine .ag-pinned-left-header,
 .ag-theme-alpine .ag-cell {
   border: none;
 }
 .ag-theme-alpine .ag-cell,
 .ag-theme-alpine .ag-header-cell {
  font-family: "Poppins", Helvetica, sans-serif;
 }
 .ag-theme-alpine .ag-header-cell {
   padding: 10px 20px;
   font-weight: 600;
   font-size: 15px;
   line-height: 40px;
 }
 
 .ag-theme-alpine .ag-header,
 .ag-theme-alpine .ag-row-odd {
   background-color: transparent;
 }
 
 .ag-theme-alpine .ag-header-row:not(:first-child) .ag-header-cell,
 .ag-theme-alpine .ag-header-cell {
   border-bottom: 1px solid #DDE2EB;
 }
 
 .ag-theme-alpine .ag-paging-panel {
   border-top-color: #DDE2EB;
 }
 
 .ag-theme-alpine .ag-row {
   height: 62px;
   border-left: 3px solid transparent;
 }
 
 .ag-theme-alpine .ag-row-hover {
   border-left-color: #64b818;
   background-color: #fcfcfc;
 }
 
 
 .ag-theme-alpine .ag-cell {
   line-height: 60px;
 }
 
 .ag-theme-alpine .ag-cell,
 .ag-theme-alpine .ag-cell .bg-transparent {
   color: #484242;
 }
 
 .ag-cell .btn-normal:last-child {
   margin-right: 0;
 }
 
 .ag-cell .btn-normal {
   padding: 8px;
   font-size: 12px;
 }
 
 .nav-tabs .nav-link {
   padding: 1rem 1rem;
 }
 
 .ag-cell-value img {
   width: auto;
   max-height: 45px;
 }

 .ag-root-wrapper {
  border-radius: 6px;
  box-shadow: 0 0 10px 0 #0001;
  border-color: #0001 !important;
}
.ag-header-cell,
.ag-header {
 border-color: #0001 !important;
}
.ag-row-odd,
.ag-header {
 background-color: #edf0f4 !important;
}